import { StyleGuideProps } from "../types";
import * as Assets from "../Assets/Krk";

export const KRKStyleGuide = (isMobile?: boolean): StyleGuideProps => {
    return {
        logo: [
            {
                title: "Guidelines",
                p1: "For immediate brand recognition and clean visual impact, the KRK logo should not be crowded by other visual elements. Do not place graphics or typography within a minimum clear space of one-quarter the height of the KRK logo in all directions.",
                p2: "For maximum legibility, the background should be solid and provide good contrast with the logo. When the background is dark, a reverse logo, in white, may be used.",
                p3: "When presented alongside other brands, the KRK logo should enjoy parity of scale with the other logo(s). The grouping of logos should observe a minimum clear space of one-quarter the height of the KRK logo in all directions, with a shared clear distance between logos one-quarter the height of the KRK logo. • All logos should appear on same or equivalent • All logos should appear at the same scale • The logos should not include slogans, urls, etc",
                img1: isMobile
                    ? Assets.LogoGuidelinesMob
                    : Assets.LogoGuidelines,
                img2: isMobile
                    ? Assets.ColorAplicationMob
                    : Assets.ColorAplication,
            },
            {
                title: "RELATIONSHIPS ",
                p: "",
                img: Assets.LogoRelationships,
            },
            {
                title: "LOGO MISUSE ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                images: [
                    {
                        img: isMobile ? Assets.logo1Mob : Assets.logo1,
                        desc: "Crop logo",
                    },
                    {
                        img: isMobile ? Assets.logo2Mob : Assets.logo2,
                        desc: "Change the transparency of the logo",
                    },
                    {
                        img: isMobile ? Assets.logo3Mob : Assets.logo3,
                        desc: "Use different colors",
                    },
                    {
                        img: isMobile ? Assets.logo4Mob : Assets.logo4,
                        desc: "Change the size or position of the logo type",
                    },
                    {
                        img: isMobile ? Assets.logo5Mob : Assets.logo5,
                        desc: "Distort the logo",
                    },
                    {
                        img: isMobile ? Assets.logo6Mob : Assets.logo6,
                        desc: "Use dropshadow or any other effect",
                    },
                    {
                        img: isMobile ? Assets.logo7Mob : Assets.logo7,
                        desc: "Outline the logo",
                    },
                    {
                        img: isMobile ? Assets.logo8Mob : Assets.logo8,
                        desc: "Use background that provide insufiicient contrast",
                    },
                    {
                        img: isMobile ? Assets.logo9Mob : Assets.logo9,
                        desc: "Re-create using any other typeface",
                    },
                    {
                        img: isMobile ? Assets.logo10Mob : Assets.logo10,
                        desc: "Skew the logo",
                    },
                ],
            },
        ],
        color: {
            title: "COLOR GUIDELINES",
            p: "These colors are the visual tone of KRK. The palette is rich in complementary and contrasting hues that provide flexibility for any application. Use of color variants is acceptable in instances where more than 4 colors are needed, such as graphic, UI elements, and information design.",
            images: [
                {
                    img: isMobile ? Assets.Pick1Mob : Assets.Pick1,
                    desc: "HEX #ffc900",
                },
                {
                    img: isMobile ? Assets.Pick2Mob : Assets.Pick2,
                    desc: "HEX #ffffff",
                },
                {
                    img: isMobile ? Assets.Pick3Mob : Assets.Pick3,
                    desc: "HEX #000000",
                },
            ],
        },
        fonts: {
            title: "Font GUIDELINES ",
            p: "For everyday use we recommend using three styles: Bold, Regular and Light.",
            fontClass: "fontKrk",
            fonts: [
                {
                    title: "EXPLORE.",
                    type: "MYRIAD PRO • BOLD • 130px",
                    ex: "HEADINGS",
                    class: "fontKrkBold",
                },
                {
                    title: "PLAY.",
                    type: "MYRIAD PRO • REGULAR • 130px",
                    ex: "PARAGRAPHS",
                    class: "fontKrkReg",
                },
                {
                    title: "SHOP.",
                    type: "MYRIAD PRO • LIGHT • 130px",
                    ex: "OPENING PARAGRAPHS",
                    class: "fontKrkLight",
                },
            ],
        },
        images: {
            title: "PHOTO APPROACH",
            p1: "KRK products may be photographed independently, grouped together, or posed with studio equipment or a variety of instruments (guitars, keyboard, cello, anything). Any people present remain anonymous, photographed from behind or out of focus, to stand in for a generic musician or producer.",
            p2: "The settings can be sunlit or studio, and can be visually complex — plenty of mixing board knobs and keyboards and cords — in contrast to the bold friendly look of the KRK yellow-coned speakers. Christmas lights or a brick wall lend a feeling of sanctuary to these music-forward creative environments.",
            images: [
                isMobile ? Assets.Image1Mob : Assets.Image1,
                isMobile ? Assets.Image4Mob : Assets.Image2,
                isMobile ? Assets.Image2Mob : Assets.Image3,
                isMobile ? Assets.Image5Mob : Assets.Image4,
                isMobile ? Assets.Image3Mob : Assets.Image5,
                isMobile ? Assets.Image6Mob : Assets.Image6,
            ],
        },
    };
};
