import { StyleGuideProps } from "../types";
import * as Assets from "../Assets/Kramer";


export const KramerStyleGuide = (isMobile?: boolean): StyleGuideProps => {
    return {
        logo: [
            {
                title: "Guidelines",
                p1: "For immediate brand recognition and clean visual impact, the Kramer logo should not be crowded by other visual elements. Do not place graphics or typography within a minimum clear space of one-quarter the height of the Kramer logo in all directions.",
                p2: "For maximum legibility, the background should be solid and provide good contrast with the logo. When the background is dark, a reverse logo, in white, may be used.",
                p3: "When presented alongside other brands, the Kramer logo should enjoy parity of scale with the other logo(s). The grouping of logos should observe a minimum clear space of one-quarter the height of the Kramer logo in all directions, with a shared clear distance between logos one-quarter the height of the Kramer logo. • All logos should appear on same or equivalent • All logos should appear at the same scale • The logos should not include slogans, urls, etc",
                img1: isMobile
                    ? Assets.LogoGuidelinesMob
                    : Assets.LogoGuidelines,
                img2: isMobile
                    ? Assets.ColorAplicationMob
                    : Assets.ColorAplication,
            },
            {
                title: "RELATIONSHIPS ",
                p: "Kramer’s logo may appear in Lockup with its tag line, “Made to Rock Hard” (no punctuation). This Lockup is preferred in designs where the brand identity appears in open space, away from other text, and at a size that makes the tag line clearly legible.",
                img: Assets.LogoRelationships,
            },
            {
                title: "LOGO MISUSE ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                images: [
                    {
                        img: isMobile ? Assets.logo1Mob : Assets.logo1,
                        desc: "Crop logo",
                    },
                    {
                        img: isMobile ? Assets.logo2Mob : Assets.logo2,
                        desc: "Change the transparency of the logo",
                    },
                    {
                        img: isMobile ? Assets.logo3Mob : Assets.logo3,
                        desc: "Use different colors",
                    },
                    {
                        img: isMobile ? Assets.logo4Mob : Assets.logo4,
                        desc: "Change the size or position of the logo type",
                    },
                    {
                        img: isMobile ? Assets.logo5Mob : Assets.logo5,
                        desc: "Distort the logo",
                    },
                    {
                        img: isMobile ? Assets.logo6Mob : Assets.logo6,
                        desc: "Use dropshadow or any other effect",
                    },
                    {
                        img: isMobile ? Assets.logo7Mob : Assets.logo7,
                        desc: "Outline the logo",
                    },
                    {
                        img: isMobile ? Assets.logo8Mob : Assets.logo8,
                        desc: "Use background that provide insufiicient contrast",
                    },
                    {
                        img: isMobile ? Assets.logo9Mob : Assets.logo9,
                        desc: "Re-create using any other typeface",
                    },
                    {
                        img: isMobile ? Assets.logo10Mob : Assets.logo10,
                        desc: "Skew the logo",
                    },
                ],
            },
        ],
        color: {
            title: "COLOR GUIDELINES",
            p: "These colors are the visual tone of Kramer. The palette is rich in complementary and contrasting hues that provide flexibility for any application. Use of color variants is acceptable in instances where more than 4 colors are needed, such as graphic, UI elements, and information design.",
            images: [
                {
                    img: isMobile ? Assets.Pick1Mob : Assets.Pick1,
                    desc: "HEX #FD0087",
                },
                {
                    img: isMobile ? Assets.Pick2Mob : Assets.Pick2,
                    desc: "HEX #FF7B00",
                },
                {
                    img: isMobile ? Assets.Pick3Mob : Assets.Pick3,
                    desc: "HEX #7A0292",
                },
                {
                    img: isMobile ? Assets.Pick4Mob : Assets.Pick4,
                    desc: "HEX #1B093F",
                },
                {
                    img: isMobile ? Assets.Pick5Mob : Assets.Pick5,
                    desc: "HEX #FFFFFF",
                },
                {
                    img: isMobile ? Assets.Pick6Mob : Assets.Pick6,
                    desc: "HEX #85CF13",
                },
                {
                    img: isMobile ? Assets.Pick7Mob : Assets.Pick7,
                    desc: "HEX #000002",
                },
            ],
        },
        fonts: {
            title: "Font GUIDELINES ",
            p: "For everyday use we recommend using three styles: Extra Bold, Regular and Light. The Die Nasty family is used for branding, reserved for logos and special uses.",
            fontClass: "fontKramer2",
            fonts: [
                {
                    title: "EXPLORE.",
                    type: "DIE NASTY • REGULAR • 130px",
                    ex: "LOGOS AND SPECIAL USES",
                    class: "fontKramerReg",
                    extraCls: "fontKramer1",
                },
                {
                    title: "PLAY.",
                    type: "OPEN SANS • EXTRA BOLD • 130px",
                    ex: "HEADINGS",
                    class: "fontKramerBold",
                },
                {
                    title: "SHOP.",
                    type: "OPEN SANS • REGULAR • 130px",
                    ex: "PARAGRAPHS",
                    class: "fontKramerReg2",
                },
                {
                    title: "LOVE.",
                    type: "OPEN SANS • LIGHT • 130px",
                    ex: "OPENING PARAGRAPHS",
                    class: "fontKramerLight",
                },
            ],
        },
        images: {
            title: "PHOTO APPROACH",
            p1: "Kramer’s visual storytelling reflects its irreverent brand identity, with a seeming disregard for careful staging or rules. Photos look like snapshots taken at night, in a club or basement or in front of a smoky stage.",
            p2: "The lighting is harsh, with flash, as if from a disposable camera or Polaroid. Instruments may be shown in their entirety, as a body only, or in closeup detail — but break those rules if it looks good. The lens is more wide angle, 16-35 mm, from-the-hip, street photography. When people are seen, they don’t tend to know or care that they’re being photographed.",
            images: [
                isMobile ? Assets.Image1Mob : Assets.Image1,
                isMobile ? Assets.Image4Mob : Assets.Image2,
                isMobile ? Assets.Image2Mob : Assets.Image3,
                isMobile ? Assets.Image5Mob : Assets.Image4,
                isMobile ? Assets.Image3Mob : Assets.Image5,
                isMobile ? Assets.Image6Mob : Assets.Image6,
            ],
        },
    };
};
