import { StyleGuideProps } from "../types";
import * as Assets from "../Assets/Maestro";


export const MaestroStyleGuide = (isMobile?: boolean): StyleGuideProps => {
    return {
        logo: [
            {
                title: "Guidelines",
                p1: "For immediate brand recognition and clean visual impact, the Maestro logo should not be crowded by other visual elements. Do not place graphics or typography within a minimum clear space of one-quarter the height of the Maestro logo in all directions.",
                p2: "For maximum legibility, the background should be solid and provide good contrast with the logo. When the background is dark, a reverse logo, in white, may be used.",
                p3: "When presented alongside other brands, the Maesrro logo should enjoy parity of scale with the other logo(s). The grouping of logos should observe a minimum clear space of one-quarter the height of the Maestro logo in all directions, with a shared clear distance between logos one-quarter the height of the Maestro logo. • All logos should appear on same or equivalent • All logos should appear at the same scale • The logos should not include slogans, urls, etc",
                img1: isMobile
                    ? Assets.LogoGuidelinesMob
                    : Assets.LogoGuidelines,
                img2: isMobile
                    ? Assets.ColorAplicationMob
                    : Assets.ColorAplication,
            },
            {
                title: "RELATIONSHIPS ",
                p: "",
                img: Assets.LogoRelationships,
            },
            {
                title: "LOGO MISUSE ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                images: [
                    {
                        img: isMobile ? Assets.logo1Mob : Assets.logo1,
                        desc: "Crop logo",
                    },
                    {
                        img: isMobile ? Assets.logo2Mob : Assets.logo2,
                        desc: "Change the transparency of the logo",
                    },
                    {
                        img: isMobile ? Assets.logo3Mob : Assets.logo3,
                        desc: "Use different colors",
                    },
                    {
                        img: isMobile ? Assets.logo4Mob : Assets.logo4,
                        desc: "Change the size or position of the logo type",
                    },
                    {
                        img: isMobile ? Assets.logo5Mob : Assets.logo5,
                        desc: "Distort the logo",
                    },
                    {
                        img: isMobile ? Assets.logo6Mob : Assets.logo6,
                        desc: "Use dropshadow or any other effect",
                    },
                    {
                        img: isMobile ? Assets.logo7Mob : Assets.logo7,
                        desc: "Outline the logo",
                    },
                    {
                        img: isMobile ? Assets.logo8Mob : Assets.logo8,
                        desc: "Use background that provide insufiicient contrast",
                    },
                    {
                        img: isMobile ? Assets.logo9Mob : Assets.logo9,
                        desc: "Re-create using any other typeface",
                    },
                    {
                        img: isMobile ? Assets.logo10Mob : Assets.logo10,
                        desc: "Skew the logo",
                    },
                ],
            },
        ],
        color: {
            title: "COLOR GUIDELINES",
            p: "These colors are the visual tone of Maestro. The palette is rich in complementary and contrasting hues that provide flexibility for any application. Use of color variants is acceptable in instances where more than 4 colors are needed, such as graphic, UI elements, and information design.",
            images: [
                {
                    img: isMobile ? Assets.Pick1Mob : Assets.Pick1,
                    desc: "HEX #C92910",
                },
                {
                    img: isMobile ? Assets.Pick2Mob : Assets.Pick2,
                    desc: "HEX #EFEFEF",
                },
                {
                    img: isMobile ? Assets.Pick3Mob : Assets.Pick3,
                    desc: "HEX #000000",
                },
                {
                    img: isMobile ? Assets.Pick4Mob : Assets.Pick4,
                    desc: "HEX #121212",
                },
                {
                    img: isMobile ? Assets.Pick5Mob : Assets.Pick5,
                    desc: "HEX #0082AD",
                },
                {
                    img: isMobile ? Assets.Pick6Mob : Assets.Pick6,
                    desc: "HEX #CDB93D",
                },
                {
                    img: isMobile ? Assets.Pick7Mob : Assets.Pick7,
                    desc: "HEX #FFFFFF",
                },
            ],
        },
        fonts: {
            title: "Font GUIDELINES ",
            p: "For everyday use we recommend using three styles: Bold, Semibold and Regular. ",
            fontClass: "fontMaestro",
            fonts: [
                {
                    title: "EXPLORE.",
                    type: "RALEWAY • BOLD • 120px",
                    ex: "HEADINGS",
                    class: "fontMaestroBold",
                },
                {
                    title: "PLAY.",
                    type: "RALEWAY • SEMIBOLD • 120px",
                    ex: "PARAGRAPHS",
                    class: "fontMaestroSemiBold",
                },
                {
                    title: "SHOP.",
                    type: "RALEWAY • REGULAR • 120px",
                    ex: "OPENING PARAGRAPHS",
                    class: "fontMaestroReg",
                },
            ],
        },
        images: {
            title: "PHOTO APPROACH",
            p1: "Maestro pedals may be photographed independently, grouped together in neat lines or grids, or posed with instruments in still life or in use. Being small items that are usually on the floor, rugs and wood-floor surfaces are carefully considered and should appear comfortably high-quality. ",
            p2: "For product and promos, the pedals often appear in white space with or without graphical elements that visually represent the effects and match the brave, bright colors of the magical gadgets themselves.",
            images: [
                isMobile ? Assets.Image1Mob : Assets.Image1,
                isMobile ? Assets.Image4Mob : Assets.Image2,
                isMobile ? Assets.Image2Mob : Assets.Image3,
                isMobile ? Assets.Image5Mob : Assets.Image4,
                isMobile ? Assets.Image3Mob : Assets.Image5,
                isMobile ? Assets.Image6Mob : Assets.Image6,
            ],
        },
    };
};
