import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import {
    Color,
    Font,
    FontGuidelines,
    Logo,
    Photo,
} from "../../screens/StyleGuideScreen/types";
import * as styles from "./brandStyleComponent.module.sass";

export interface PhotoStyleComponentProps {
    content: Photo;
}


const PhotoStyleComponent: FC<PhotoStyleComponentProps> = (props) => {
    const { content } = props;
    const { isMobile } = useIsMobileViewport();

    return (
        <div
            className={`${styles.brandStyleGuide} flex flex-col pt-[2rem] md:pt-[5rem] md:pb-32 bg-black text-white relative top-[-3rem]`}>
            <h2 className='font-bold text-[58px] md:text-6xl leading-[60px] uppercase'>
                {content.title}
            </h2>
            {isMobile && (
                <div className='flex flex-col'>
                    <p className='text-sm pt-[1.5rem]'>{content.p1}</p>
                    <p className='text-sm pt-[1.5rem]'>{content.p2}</p>
                    <div className='flex flex-col gap-8 pt-8'>
                        {content.images.map((img, id) => {
                            return <img src={img} className='' />;
                        })}
                    </div>
                </div>
            )}
            {!isMobile && (
                <div className='flex flex-row'>
                    <div className='flex flex-col flex-1'>
                        <p className='text-sm pt-[1.5rem]'>{content.p1}</p>
                        <div className='flex flex-col gap-14 pt-10 w-[80%]'>
                            {content.images.map((img, id) => {
                                if (id < 3) {
                                    return <img src={img} className='' />;
                                }
                            })}
                        </div>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='text-sm pt-[1.5rem] pl-6'>{content.p2}</p>
                        <div className='relative flex flex-col'>
                            {content.images.map((img, id) => {
                                if (id === 5) {
                                    return (
                                        <img
                                            src={img}
                                            className='pt-8 mb-[-100%] mt-12 z-[1]'
                                        />
                                    );
                                }
                                if (id >= 3) {
                                    return <img src={img} className='pt-16' />;
                                }
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PhotoStyleComponent;
