import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import {
    Color,
    Font,
    FontGuidelines,
    Logo,
    Photo,
} from "../../screens/StyleGuideScreen/types";
import Button from "../Button";
import * as styles from "./brandStyleComponent.module.sass";

export interface AssetStyleComponentProps {
    // content: Photo;
}

const AssetStyleComponent: FC<AssetStyleComponentProps> = (props) => {
    const {  } = props;
    const { isMobile } = useIsMobileViewport();

    return (
        <div
            className={`${styles.brandStyleGuide} flex flex-col pt-[2rem] md:pt-[7rem] items-center pb-12 md:pb-20 relative top-[-3rem]`}>
            <h2 className='font-bold text-2xl md:text-3xl uppercase'>
                Download Visual Assets
            </h2>
            <p className='text-sm pt-[1rem] md:max-w-[28rem] text-center'>
                Download logos, fonts, and color swatches from our online asset
                library. <br /> (Password may be required.){" "}
            </p>
            <Button
                isGold
                hasArrow
                classes='mt-[2rem]'
                externalLink='https://brandfolder.com/s/2hqw67whmnbj54jm6g8shs3'>
                visit library
            </Button>
        </div>
    );
};

export default AssetStyleComponent;
