import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { Color, Font, Logo, Photo } from "../../screens/StyleGuideScreen/types";
import * as styles from "./brandStyleComponent.module.sass";

export interface ColorStyleComponentProps {
    content: Color;
}

const ColorStyleComponent: FC<ColorStyleComponentProps> = (props) => {
    const { content } = props;
    const { isMobile } = useIsMobileViewport();

    return (
        <div
            className={`${styles.brandStyleGuide} flex flex-col pt-[2rem] md:pt-[5rem] pb-24 bg-gray-4 relative top-[-3rem]`}>
            <h2 className='font-bold text-[58px] md:text-6xl uppercase'>
                {content.title}
            </h2>
            {isMobile && (
                <p className='text-sm max-w-[30rem] pt-[1.5rem]'>{content.p}</p>
            )}

            <div className='grid grid-cols-[auto_auto] md:flex flex-row py-8 justify-between  gap-[1rem]'>
                {content.images.map((images, id) => {
                    if (id < 4)
                        return (
                            <div className='max-w-[190px] flex flex-col items-center '>
                                <img src={images.img} />
                                {!isMobile && (
                                    <h6 className='text-sm uppercase'>
                                        {images.desc}
                                    </h6>
                                )}
                            </div>
                        );
                })}
            </div>
            <div className='flex flex-row pt-4  justify-between'>
                {!isMobile && (
                    <p className='text-sm max-w-[30rem] pt-[1.5rem]'>
                        {content.p}
                    </p>
                )}
                <div className='flex flex-row  justify-between  gap-[1rem]'>
                    {content.images.map((images, id) => {
                        if (id >= 4)
                            return (
                                <div className='max-w-[190px] flex flex-col items-center'>
                                    <img src={images.img} />
                                    {!isMobile && (
                                        <h6 className='text-sm uppercase'>
                                            {images.desc}
                                        </h6>
                                    )}
                                </div>
                            );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ColorStyleComponent;
