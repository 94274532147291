import { StyleGuideProps } from "../types";
import * as Assets from "../Assets/MesaBoogie";


export const MesaBoogieStyleGuide = (isMobile?: boolean): StyleGuideProps => {
    return {
        logo: [
            {
                title: "Guidelines",
                p1: "For immediate brand recognition and clean visual impact, the Mesa/Boogie logo should not be crowded by other visual elements. Do not place graphics or typography within a minimum clear space of one-quarter the height of the Mesa/Boogie logo in all directions.",
                p2: "For maximum legibility, the background should be solid and provide good contrast with the logo. When the background is dark, a reverse logo, in white, may be used.",
                p3: "When presented alongside other brands, the Mesa/Boogie logo should enjoy parity of scale with the other logo(s). The grouping of logos should observe a minimum clear space of one-quarter the height of the Mesa/Boogie logo in all directions, with a shared clear distance between logos one-quarter the height of the Mesa/Boogie logo. • All logos should appear on same or equivalent • All logos should appear at the same scale • The logos should not include slogans, urls, etc",
                img1: isMobile
                    ? Assets.LogoGuidelinesMob
                    : Assets.LogoGuidelines,
                img2: isMobile
                    ? Assets.ColorAplicationMob
                    : Assets.ColorAplication,
            },
            {
                title: "RELATIONSHIPS ",
                p: "",
                img: Assets.LogoRelationships,
            },
            {
                title: "LOGO MISUSE ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                images: [
                    {
                        img: isMobile ? Assets.logo1Mob : Assets.logo1,
                        desc: "Crop logo",
                    },
                    {
                        img: isMobile ? Assets.logo2Mob : Assets.logo2,
                        desc: "Change the transparency of the logo",
                    },
                    {
                        img: isMobile ? Assets.logo3Mob : Assets.logo3,
                        desc: "Use different colors",
                    },
                    {
                        img: isMobile ? Assets.logo4Mob : Assets.logo4,
                        desc: "Change the size or position of the logo type",
                    },
                    {
                        img: isMobile ? Assets.logo5Mob : Assets.logo5,
                        desc: "Distort the logo",
                    },
                    {
                        img: isMobile ? Assets.logo6Mob : Assets.logo6,
                        desc: "Use dropshadow or any other effect",
                    },
                    {
                        img: isMobile ? Assets.logo7Mob : Assets.logo7,
                        desc: "Outline the logo",
                    },
                    {
                        img: isMobile ? Assets.logo8Mob : Assets.logo8,
                        desc: "Use background that provide insufiicient contrast",
                    },
                    {
                        img: isMobile ? Assets.logo9Mob : Assets.logo9,
                        desc: "Re-create using any other typeface",
                    },
                    {
                        img: isMobile ? Assets.logo10Mob : Assets.logo10,
                        desc: "Skew the logo",
                    },
                ],
            },
        ],
        color: {
            title: "COLOR GUIDELINES",
            p: "These colors are the visual tone of Mesa/Boogie. The palette is rich in complementary and contrasting hues that provide flexibility for any application. Use of color variants is acceptable in instances where more than 4 colors are needed, such as graphic, UI elements, and information design.",
            images: [
                {
                    img: isMobile ? Assets.Pick1Mob : Assets.Pick1,
                    desc: "HEX #ffffff",
                },
                {
                    img: isMobile ? Assets.Pick3Mob : Assets.Pick3,
                    desc: "HEX #000000",
                },
                {
                    img: isMobile ? Assets.Pick4Mob : Assets.Pick4,
                    desc: "HEX #292929",
                },
                {
                    img: isMobile ? Assets.Pick5Mob : Assets.Pick5,
                    desc: "HEX #ff6600",
                },
                {
                    img: isMobile ? Assets.Pick6Mob : Assets.Pick6,
                    desc: "HEX #ea3926",
                },
                {
                    img: isMobile ? Assets.Pick7Mob : Assets.Pick7,
                    desc: "HEX HEX #002329",
                },
            ],
        },
        fonts: {
            title: "Font GUIDELINES ",
            p: "For everyday use we recommend using three styles: Extra Bold, Regular and Light.",
            fontClass: "fontMesaBoogie",
            fonts: [
                {
                    title: "EXPLORE.",
                    type: "PROXIMA NOVA • EXTRA BOLD • 120px",
                    ex: "HEADINGS",
                    class: "fontMesaBoogieBold",
                },
                {
                    title: "PLAY.",
                    type: "PROXIMA NOVA • REGULAR • 120px",
                    ex: "PARAGRAPHS",
                    class: "fontMesaBoogieReg",
                },
                {
                    title: "SHOP.",
                    type: "PROXIMA NOVA • LIGHT • 120px",
                    ex: "OPENING PARAGRAPHS",
                    class: "fontMesaBoogieLight",
                },
            ],
        },
        images: {
            title: "PHOTO APPROACH",
            p1: "Mesa/Boogie products may be photographed independently, grouped together, or posed with instruments and/or musicians. The rooms tend to be dark and uncluttered, evocative of the studio or stage, and the amplifier or cabinet is illuminated in contoured light, with plenty of highlights and shadows. ",
            p2: "The box of the product is almost always shot at an angle, to feature multiple sides and the rich contrasting textures thereof. Even when shown alongside instruments, cords and plugs are rarely visible.",
            images: [
                isMobile ? Assets.Image1Mob : Assets.Image1,
                isMobile ? Assets.Image4Mob : Assets.Image2,
                isMobile ? Assets.Image2Mob : Assets.Image3,
                isMobile ? Assets.Image5Mob : Assets.Image4,
                isMobile ? Assets.Image3Mob : Assets.Image5,
                isMobile ? Assets.Image6Mob : Assets.Image6,
            ],
        },
    };
};
