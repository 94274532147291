import React, { FC } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./index.sass";
import Tabs from "../../components/Tabs";
import { TabData } from "../../components/Tabs/types";
import BrandStyleComponent from "../../components/BrandStyleComponent";

export interface BrandStyleGuideProps {
    data: TabData[];
    activeTabId: number;
}

const BrandStyleGuide: FC<BrandStyleGuideProps> = ({data, activeTabId}) => {
    const { isMobile } = useIsMobileViewport();
    const { TabLinks, TabContent, activeTab } = Tabs({
        data,
        hasInfiniteScroll: true,
    });
    return (
        <>
            {!isMobile && (
                <div className='absolute h-full md:left-[3rem] z-[1] mix-blend-difference '>
                    <TabLinks
                        isVertical
                        classes='!sticky top-[25vh] mt-[8vh] self-start mix-blend-difference text-white border-white '
                    />
                </div>
            )}

            <div className='mt-[8vh] '>
                <TabContent scrollBelowHeader={"top-[-25vh]"}>
                    {(currTab, id) => {
                        return (
                            <BrandStyleComponent
                                content={currTab.content}
                                contentType={currTab.contentType}
                            />
                        );
                    }}
                </TabContent>
            </div>
        </>
    );
};

export default BrandStyleGuide;
