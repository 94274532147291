import React, { useEffect, useRef } from "react";
import type { HeadFC } from "gatsby";
import StyleGuideScreen from "../../screens/StyleGuideScreen";
import useIsInsideViewport from "../../hooks/useIsInsideViewport";

const StyleGuidePage = () => {
    return <StyleGuideScreen />;
    // const refs = new Array(2);

    // for (let i = 0; i < 2; i++){
    //     refs[i] = useRef(null);
    // }

    // console.log('refs',refs);

    // const isInViewport1 = useIsInsideViewport(refs[0]);
    // const isInViewport2 = useIsInsideViewport(refs[1]);

    // return (
    //     <div>
    //         <div ref={refs[0]}>Top div {isInViewport1 && "| in viewport ✅"}</div>

    //         <div style={{ height: "155rem" }} />

    //         <div ref={refs[1]}>
    //             Bottom div {isInViewport2 && "| in viewport ✅"}
    //         </div>
    //     </div>
    // );
};

export default StyleGuidePage;

export const Head: HeadFC = () => <title>Style Guide</title>;
