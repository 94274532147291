import { StyleGuideProps } from "../types";
import * as Assets from "../Assets/Gibson";
import GibsonHero from "../../../assets/images/Desktop/StyleGuide/Gibson/Bg.png";
import GibsonMobileHero from "../../../assets/images/Mobile/StyleGuide/Gibson/Bg.png";

import EpiphoneHero from "../../../assets/images/Desktop/StyleGuide/Epiphone/Bg.png";
import EpiphoneMobileHero from "../../../assets/images/Mobile/StyleGuide/Epiphone/Bg.png";

import KRKHero from "../../../assets/images/Desktop/StyleGuide/KRK/Bg.png";
import KRKMobileHero from "../../../assets/images/Mobile/StyleGuide/KRK/Bg.png";

import KramerHero from "../../../assets/images/Desktop/StyleGuide/Kramer/Bg.png";
import KramerMobileHero from "../../../assets/images/Mobile/StyleGuide/Kramer/Bg.png";

import MaestroHero from "../../../assets/images/Desktop/StyleGuide/Maestro/Bg.png";
import MaestroMobileHero from "../../../assets/images/Mobile/StyleGuide/Maestro/Bg.png";

import MesaBoogieHero from "../../../assets/images/Desktop/StyleGuide/MesaBoogie/Bg.png";
import MesaBoogieMobileHero from "../../../assets/images/Mobile/StyleGuide/MesaBoogie/Bg.png";

export const HeroImages = (isMobile?: boolean) => {
    return [
        isMobile ? GibsonMobileHero : GibsonHero,
        isMobile ? EpiphoneMobileHero : EpiphoneHero,
        isMobile ? KramerMobileHero : KramerHero,
        isMobile ? MesaBoogieMobileHero : MesaBoogieHero,
        isMobile ? KRKMobileHero : KRKHero,
        isMobile ? MaestroMobileHero : MaestroHero,
    ];
}

export const GibsonStyleGuide = (isMobile?: boolean): StyleGuideProps => {
    return {
        logo: [
            {
                title: "Guidelines",
                p1: "The Gibson logo is rendered at an angle of 17.9  with a registered mark in the upper right. For immediate brand recognition and clean visual impact, the Gibson logo should not be crowded by other visual elements. Do not place graphics or typography within a minimum clear space of onequarter the height of the Gibson logo in all directions.",
                p2: "For maximum legibility, the background should be solid and provide good contrast with the logo. When the background is dark, a reverse logo, in white, may be used.",
                p3: "When presented alongside other brands, the Gibson logo should enjoy parity of scale with the other logo(s). The grouping of logos should observe a minimum clear space of one-quarter the height of the Gibson logo in all directions, with a shared clear distance between logos one-quarter the height of the Gibson logo. • All logos should appear on same or equivalent • All logos should appear at the same scale • The logos should not include slogans, urls, etc",
                img1: isMobile
                    ? Assets.LogoGuidelinesMob
                    : Assets.LogoGuidelines,
                img2: isMobile
                    ? Assets.ColorAplicationMob
                    : Assets.ColorAplication,
            },
            {
                title: "RELATIONSHIPS ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                img: Assets.LogoRelationships,
            },
            {
                title: "LOGO MISUSE ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                images: [
                    {
                        img: isMobile ? Assets.logo1Mob : Assets.logo1,
                        desc: "Crop logo",
                    },
                    {
                        img: isMobile ? Assets.logo2Mob : Assets.logo2,
                        desc: "Change the transparency of the logo",
                    },
                    {
                        img: isMobile ? Assets.logo3Mob : Assets.logo3,
                        desc: "Use different colors",
                    },
                    {
                        img: isMobile ? Assets.logo4Mob : Assets.logo4,
                        desc: "Change the size or position of the logo type",
                    },
                    {
                        img: isMobile ? Assets.logo5Mob : Assets.logo5,
                        desc: "Distort the logo",
                    },
                    {
                        img: isMobile ? Assets.logo6Mob : Assets.logo6,
                        desc: "Use dropshadow or any other effect",
                    },
                    {
                        img: isMobile ? Assets.logo7Mob : Assets.logo7,
                        desc: "Outline the logo",
                    },
                    {
                        img: isMobile ? Assets.logo8Mob : Assets.logo8,
                        desc: "Use background that provide insufiicient contrast",
                    },
                    {
                        img: isMobile ? Assets.logo9Mob : Assets.logo9,
                        desc: "Re-create using any other typeface",
                    },
                    {
                        img: isMobile ? Assets.logo10Mob : Assets.logo10,
                        desc: "Skew the logo",
                    },
                ],
            },
        ],
        color: {
            title: "COLOR GUIDELINES",
            p: "These colors are the visual tone of Gibson. The palette is rich in complementary and contrasting hues that provide flexibility for any application. Use of color variants is acceptable in instances where more than 4 colors are needed, such as graphic, UI elements, and information design.",
            images: [
                {
                    img: isMobile ? Assets.Pick1Mob : Assets.Pick1,
                    desc: "HEX #000000",
                },
                {
                    img: isMobile ? Assets.Pick2Mob : Assets.Pick2,
                    desc: "HEX #FFFFFF",
                },
                {
                    img: isMobile ? Assets.Pick3Mob : Assets.Pick3,
                    desc: "HEX #86764E",
                },
                {
                    img: isMobile ? Assets.Pick4Mob : Assets.Pick4,
                    desc: "HEX #292828",
                },
                {
                    img: isMobile ? Assets.Pick5Mob : Assets.Pick5,
                    desc: "HEX #8e1204",
                },
                {
                    img: isMobile ? Assets.Pick6Mob : Assets.Pick6,
                    desc: "HEX #5a75a0",
                },
                {
                    img: isMobile ? Assets.Pick7Mob : Assets.Pick7,
                    desc: "HEX #FF3200",
                },
            ],
        },
        fonts: {
            title: "Font GUIDELINES ",
            p: "For everyday use we recommend using three styles: Bold condensed, Regular and Light. The condensed version of Trade Gothic is used for branding, reserved for logos and special uses.",
            fontClass: "fontGibson",
            fonts: [
                {
                    title: "EXPLORE.",
                    type: "TRADE GOTHIC • BOLD CONDENSED • 146px",
                    ex: "LOGOS AND SPECIAL USES",
                    class: "fontGibsonBoldCond",
                },
                {
                    title: "PLAY.",
                    type: "TRADE GOTHIC • BOLD • 146px",
                    ex: "HEADINGS",
                    class: "fontGibsonBold",
                },
                {
                    title: "SHOP.",
                    type: "TRADE GOTHIC • REGULAR • 146px",
                    ex: "PARAGRAPHS",
                    class: "fontGibsonReg",
                },
                {
                    title: "LOVE.",
                    type: "TRADE GOTHIC • LIGHT • 146px",
                    ex: "OPENING PARAGRAPHS",
                    class: "fontGibsonLight",
                },
            ],
        },
        images: {
            title: "PHOTO APPROACH",
            p1: "Gibson’s visual storytelling reflects its classic American brand identity, with an emphasis on craftsmanship. In photography, raw materials such as leather, wood, brick, and metal abound in the setting, presenting as clean and refined, even when aged.",
            p2: "The lighting is moody, with enough shadow to accent the arched tops and other subtle contours and curves. Instruments should be shown in their entirety, or as a body only, or in closeup detail — never a partial neck or haphazard crop. The lens is in the portrait range, 35 - 70mm. When people are seen, they tend to be in a professional setting: the studio or stage.",
            images: [
                isMobile ? Assets.Image1Mob : Assets.Image1,
                isMobile ? Assets.Image4Mob : Assets.Image2,
                isMobile ? Assets.Image2Mob : Assets.Image3,
                isMobile ? Assets.Image5Mob : Assets.Image4,
                isMobile ? Assets.Image3Mob : Assets.Image5,
                isMobile ? Assets.Image6Mob : Assets.Image6,
            ],
        },
    };
};
