import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { Color, Font, FontGuidelines, Logo, Photo } from "../../screens/StyleGuideScreen/types";
import * as styles from "./brandStyleComponent.module.sass";

export interface FontStyleComponentProps {
    content: Font;
}

export interface FontDescProps {
    content: FontGuidelines;
    cls1?: string;
    cls2?: string;
    cls3?: string;
    fontClass?: string;
}

const FontDesc: FC<FontDescProps> = (props) => {
    const { content, cls1, cls2, cls3, fontClass } = props;
    return (
        <div className=' '>
            <h1
                className={`text-[70px] smScreens:!text-[100px] md:text-[146px] uppercase ${cls1}`}>
                {content.title}
            </h1>
            <p className={`text-sm uppercase  ${fontClass}`}>{content.type}</p>
            <div className='text-sm flex flex-col md:flex-row md:gap-[10rem] pt-4'>
                <p className={`${fontClass} ${cls2}`}>
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    <br />
                    abcdefghijklmnopqrstuvwxyz
                    <br />
                    1234567890!@£$%^&*
                </p>
                <p className={`${fontClass} ${cls3} pt-6 md:pt-0`}>
                    USAGE CASE EXAMPLE:
                    <br />
                    {content.ex}
                </p>
            </div>
        </div>
    );
};

const FontStyleComponent: FC<FontStyleComponentProps> = (props) => {
    const { content } = props;
    const { title , p, fontClass, fonts} = content;
    const { isMobile } = useIsMobileViewport();

    return (
        <div
            className={`${styles.brandStyleGuide} flex flex-col pt-[2rem] md:pt-[5rem] pb-32  relative top-[-3rem]`}>
            <h2 className='font-bold text-[58px] md:text-6xl leading-[60px] uppercase'>
                {title}
            </h2>
            <p className='text-sm max-w-[30rem] pt-[1.5rem]'>{p}</p>

            <div className='flex flex-wrap justify-between gap-12 pt-[1.5rem] md:pt-[3rem]'>
                {/* <div className='flex flex-col md:flex-row gap-4 justify-between'> */}
                <FontDesc
                    content={fonts[0]}
                    cls1={fonts[0].class}
                    cls2={`font-bold ${fonts[0].extraCls}`}
                    fontClass={fontClass}
                />
                <FontDesc
                    content={fonts[1]}
                    cls1={fonts[1].class}
                    cls2={`font-bold`}
                    cls3=''
                    fontClass={fontClass}
                />
                {/* </div>
                <div className='flex flex-col md:flex-row gap-4 justify-between'> */}
                <FontDesc
                    content={fonts[2]}
                    // cls1='font-normal'
                    cls1={fonts[2].class}
                    cls2='font-normal'
                    cls3=''
                    fontClass={fontClass}
                />
                {!isMobile && fonts[3] && (
                    <FontDesc
                        content={fonts[3]}
                        // cls1='font-normal'
                        cls1={fonts[3].class}
                        cls2='font-normal'
                        cls3=''
                        fontClass={fontClass}
                    />
                )}
                {/* </div> */}
            </div>
        </div>
    );
};

export default FontStyleComponent;
