import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { Color, Font, Logo, Photo } from "../../screens/StyleGuideScreen/types";
import * as styles from "./brandStyleComponent.module.sass";

export interface LogoStyleComponentProps {
    content: Logo;
}

const LogoStyleComponent: FC<LogoStyleComponentProps> = (props) => {
    const { content } = props;
    const LogoGuidelines = content[0];
    const LogoRelationships = content[1];
    const LogoStyles = content[2];
    const { isMobile } = useIsMobileViewport();

    const Guidelines = () => {
        if(isMobile)
        return (
            <div
                className={`${styles.brandStyleGuide} flex flex-col gap-[2rem]`}>
                <h2 className='font-bold text-[58px] uppercase'>
                    {LogoGuidelines.title}
                </h2>
                <div className='flex flex-col gap-[1rem]'>
                    <p className='text-sm	'>{LogoGuidelines.p1}</p>
                    <p className='text-sm	'>{LogoGuidelines.p2}</p>
                </div>
                <img src={LogoGuidelines.img1} />
                <p className='text-sm'>{LogoGuidelines.p3}</p>
                <img src={LogoGuidelines.img2} />
            </div>
        );

        return (
            <div className={`${styles.brandStyleGuide} flex flex-row`}>
                <div className='flex flex-col flex-1 justify-between'>
                    <h2 className='font-bold text-[58px] md:text-6xl uppercase'>
                        {LogoGuidelines.title}
                    </h2>
                    <div className='flex flex-col gap-[1rem] mt-[1rem]'>
                        <p className='text-sm	'>{LogoGuidelines.p1}</p>
                        <p className='text-sm	'>{LogoGuidelines.p2}</p>
                    </div>
                    <img src={LogoGuidelines.img2} className='mt-[3rem]' />
                </div>
                <div className='flex flex-col gap-[3rem] flex-1	justify-end'>
                    <img src={LogoGuidelines.img1} />
                    <p className='text-sm pl-[3rem]'>{LogoGuidelines.p3}</p>
                </div>
            </div>
        );
    }

    const Relationships = () => {
        if (isMobile) {
            return <div/>
        }
        return (
            <div
                className={`${styles.brandStyleGuide} flex flex-col pt-[8rem]`}>
                <div className='flex flex-row gap-[3rem]'>
                    <h2 className='font-bold text-[58px] md:text-6xl uppercase'>
                        {LogoRelationships.title}
                    </h2>
                    <p className='text-sm max-w-[27rem]'>
                        {LogoRelationships.p}
                    </p>
                </div>
                <img src={LogoRelationships.img} className=' pt-[2rem]' />
            </div>
        );
    };

    const LogoGuide = () => {
        return (
            <div
                className={`${styles.brandStyleGuide} flex flex-col pt-[5rem] md:pt-[8rem] pb-12`}>
                <div className='flex flex-col md:flex-row gap-[3rem]'>
                    <h2 className='font-bold text-[58px] md:text-6xl uppercase'>
                        {LogoStyles.title}
                    </h2>
                    <p className='text-sm max-w-[35rem]'>{LogoStyles.p}</p>
                </div>
                <div
                    className={`grid pt-[4rem] justify-around md:gap-y-[3rem] ${styles.logoImagesGrid}`}>
                    {LogoStyles.images.map((logos) => {
                        return (
                            <div className='w-fit flex flex-col items-center'>
                                <img src={logos.img} className='max-h-[90px]' />
                                <div className='flex flex-col items-center max-w-[140px] text-center pt-4 pb-12'>
                                    <p className='text-xs font-bold'>Do Not-</p>
                                    <p className='text-xs'>{logos.desc}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };


    return (
        <>
            <Guidelines />
            <Relationships />
            <LogoGuide/>
        </>
    )
};

export default LogoStyleComponent;
