import React, { FC, useState } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { Color, Font, Logo, Photo } from "../../screens/StyleGuideScreen/types";
import LogoStyleComponent from "./Logo";
import ColorStyleComponent from "./Color";
import FontStyleComponent from "./Font";
import PhotoStyleComponent from "./Photo";
import AssetStyleComponent from "./Asset";
import * as styles from "./brandStyleComponent.module.sass";


export interface BrandStyleComponentProps {
    content?: string | Logo | Color | Font | Photo;
    contentType?: "string" | "logo" | "color" | "images" | "font" | "assets";
}


const BrandStyleComponent: FC<BrandStyleComponentProps> = (props) => {
    const { content, contentType = "string" } = props;
    const { isMobile } = useIsMobileViewport();
    

    if (contentType === 'logo') {
        return <LogoStyleComponent content={content as Logo} />;
    }

    if (contentType === 'color') {
        return <ColorStyleComponent content={content as Color} />
    }
    
    if (contentType === "font") {
        return <FontStyleComponent content={content as Font} />;
    }

    if (contentType === "images") {
        return <PhotoStyleComponent content={content as Photo} />;
    }

    if (contentType === "assets") {
        return <AssetStyleComponent />;
    }
    
    if (contentType === "string") return (
        <div className='pb-[50vh]  container-padding md:pl-[11rem]'>
            {content as string}
        </div>
    );

    return <div>None</div>
};

export default BrandStyleComponent;
