import React, { FC } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./index.sass";
import Tabs from "../../components/Tabs";
import BrandStyleGuide from "./BrandStyleGuide";
import { StyleGuideData } from "./StyleGuideData";
import { TabData } from "../../components/Tabs/types";
import { HeroImages } from "./Data/Gibson";
import { brands } from "../BrandsScreen/types";
import Button from "../../components/Button";

export interface OverviewScreenProps {}


const StyleGuideScreen: FC<OverviewScreenProps> = (props) => {
    const { isMobile, isSmallScreen } = useIsMobileViewport();
    const styleContent = StyleGuideData(isMobile);
    const { TabLinks, TabContent, activeTab } = Tabs({
        data: [...styleContent],
        addIdToLink: true,
        // hasInfiniteScroll: true,
    });
    return (
        <div className='bg-white style-guide-screen h-screen snap-y snap-mandatory overflow-y-scroll '>
            <Header shrinkOnScroll={isMobile} />
            <div className='relative bg-white'>
                <div
                    className={`relative bg-cover bg-no-repeat bg-right styleGuideBg mt-[-10vh] md:mt-0`}
                    style={{
                        backgroundImage: `url(${
                            HeroImages(isSmallScreen)[activeTab]
                        })`,
                    }}>
                    {/* {!isMobile && ( */}
                    <div
                        className={`absolute text-white container-margin lg:right-[6%] lg:top-[30%] bottom-[12vh] border-0 border-t-4 border-${styleContent[activeTab].id} py-4`}>
                        <h1 className='uppercase font-bold  text-6xl lg:text-7xl leading-[70px]'>
                            creative {!isSmallScreen && <br />} expression
                        </h1>
                        <p
                            className={`uppercase ${
                                !isSmallScreen ? "font-bold" : ""
                            } text-sm leading-[35px]`}>
                            Visual Guidelines
                        </p>
                    </div>
                    {/* )} */}
                </div>
                <TabLinks
                    classes='!sticky !bottom-0 top-[1vh] md:top-[10vh] z-[2] bg-black h-[12vh] md:h-[10vh] container-padding text-white flex w-[100%] gap-[2rem] md:justify-center'
                    tabLinksClasses='hover:!text-black border-transparent hover:!bg-white !text-sm md:!text-base '
                    inActiveTabClass='!border-transparent '
                    activeTabClass={`border-${styleContent[activeTab].id}`}
                />

                <div className='relative'>
                    <TabContent
                        classes='flex'
                        scrollBelowHeader={"top-[-17vh]"}>
                        {(data, id) => (
                            <BrandStyleGuide
                                data={data.tabs as TabData[]}
                                activeTabId={id}
                            />
                        )}
                    </TabContent>
                </div>
                {/* <div className='flex flex-row justify-center py-16 container-padding'>
                    <div className='w-[28rem] flex flex-col items-center'>
                        <h3 className='text-3xl	font-bold uppercase pb-4'>
                            DOWNLOAD our brand book
                        </h3>
                        <p className='text-center text-sm pb-12'>
                            Gibson Brands is proud to serve the world. Click on
                            a language below to download a translated PDF of the
                            Gibson Brand Book.{" "}
                        </p>
                        <Button
                            isGold
                            hasArrow
                            externalLink='https://brandfolder.com/s/2hqw67whmnbj54jm6g8shs3'>
                            visit library
                        </Button>
                    </div>
                </div> */}
                <Footer />
            </div>
        </div>
    );
};

export default StyleGuideScreen;
