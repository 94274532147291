import { TabData } from "../../components/Tabs/types";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import { brands } from "../BrandsScreen/types";
import * as Assets from './Assets/Gibson';
import { EpiphoneStyleGuide } from "./Data/Epiphone";
import { GibsonStyleGuide } from "./Data/Gibson";
import { KramerStyleGuide } from "./Data/Kramer";
import { KRKStyleGuide } from "./Data/Krk";
import { MaestroStyleGuide } from "./Data/Maestro";
import { MesaBoogieStyleGuide } from "./Data/MesaBoogie";
import { StyleGuideProps } from "./types";


export const StyleGuideData = (isMobile?: boolean): TabData[] => [
    {
        title: "Gibson",
        id: "gibson",
        tabs: [
            {
                title: "Logo",
                id: "gibson-logo",
                content: GibsonStyleGuide(isMobile).logo,
                contentType: "logo",
            },
            {
                title: "Color",
                id: "gibson-color",
                content: GibsonStyleGuide(isMobile).color,
                contentType: "color",
            },
            {
                title: "Fonts",
                id: "gibson-fonts",
                content: GibsonStyleGuide(isMobile).fonts,
                contentType: "font",
            },
            {
                title: "images",
                id: "gibson-images",
                content: GibsonStyleGuide(isMobile).images,
                contentType: "images",
            },
            {
                title: "Assets",
                id: "gibson-assets",
                contentType: "assets",
            },
        ],
    },
    {
        title: "Epiphone",
        id: "epiphone",
        tabs: [
            {
                title: "Logo",
                id: "epiphone-logo",
                content: EpiphoneStyleGuide(isMobile).logo,
                contentType: "logo",
            },
            {
                title: "Color",
                id: "epiphone-color",
                content: EpiphoneStyleGuide(isMobile).color,
                contentType: "color",
            },
            {
                title: "Fonts",
                id: "epiphone-fonts",
                content: EpiphoneStyleGuide(isMobile).fonts,
                contentType: "font",
            },
            {
                title: "images",
                id: "epiphone-images",
                content: EpiphoneStyleGuide(isMobile).images,
                contentType: "images",
            },
            {
                title: "Assets",
                id: "epiphone-assets",
                contentType: "assets",
            },
        ],
    },
    {
        title: "Kramer",
        id: "kramer",
        tabs: [
            {
                title: "Logo",
                id: "kramer-logo",
                content: KramerStyleGuide(isMobile).logo,
                contentType: "logo",
            },
            {
                title: "Color",
                id: "kramer-color",
                content: KramerStyleGuide(isMobile).color,
                contentType: "color",
            },
            {
                title: "Fonts",
                id: "kramer-fonts",
                content: KramerStyleGuide(isMobile).fonts,
                contentType: "font",
            },
            {
                title: "images",
                id: "kramer-images",
                content: KramerStyleGuide(isMobile).images,
                contentType: "images",
            },
            {
                title: "Assets",
                id: "kramer-assets",
                contentType: "assets",
            },
        ],
    },
    {
        title: "Mesa/Boogie",
        id: "mesaboogie",
        tabs: [
            {
                title: "Logo",
                id: "logo",
                content: MesaBoogieStyleGuide(isMobile).logo,
                contentType: "logo",
            },
            {
                title: "Color",
                id: "color",
                content: MesaBoogieStyleGuide(isMobile).color,
                contentType: "color",
            },
            {
                title: "Fonts",
                id: "fonts",
                content: MesaBoogieStyleGuide(isMobile).fonts,
                contentType: "font",
            },
            {
                title: "images",
                id: "images",
                content: MesaBoogieStyleGuide(isMobile).images,
                contentType: "images",
            },
            {
                title: "Assets",
                id: "assets",
                contentType: "assets",
            },
        ],
    },
    {
        title: "krk",
        id: "krk",
        tabs: [
            {
                title: "Logo",
                id: "krk-logo",
                content: KRKStyleGuide(isMobile).logo,
                contentType: "logo",
            },
            {
                title: "Color",
                id: "krk-color",
                content: KRKStyleGuide(isMobile).color,
                contentType: "color",
            },
            {
                title: "Fonts",
                id: "krk-fonts",
                content: KRKStyleGuide(isMobile).fonts,
                contentType: "font",
            },
            {
                title: "images",
                id: "krk-images",
                content: KRKStyleGuide(isMobile).images,
                contentType: "images",
            },
            {
                title: "Assets",
                id: "krk-assets",
                contentType: "assets",
            },
        ],
    },
    {
        title: "Maestro ",
        id: "maestro",
        tabs: [
            {
                title: "Logo",
                id: "maestro-logo",
                content: MaestroStyleGuide(isMobile).logo,
                contentType: "logo",
            },
            {
                title: "Color",
                id: "maestro-color",
                content: MaestroStyleGuide(isMobile).color,
                contentType: "color",
            },
            {
                title: "Fonts",
                id: "maestro-fonts",
                content: MaestroStyleGuide(isMobile).fonts,
                contentType: "font",
            },
            {
                title: "images",
                id: "maestro-images",
                content: MaestroStyleGuide(isMobile).images,
                contentType: "images",
            },
            {
                title: "Assets",
                id: "maestro-assets",
                contentType: "assets",
            },
        ],
    },
];


