import React, { FC } from "react";
import Button from "../Button";
import Epiphone from "../../assets/svg/Footer/Epiphone.svg";
import Gibson from "../../assets/svg/Footer/Gibson.svg";
import Kramer from "../../assets/svg/Footer/Kramer.svg";
import KRK from "../../assets/svg/Footer/KRK.svg";
import Maestro from "../../assets/svg/Footer/Maestro.svg";
import MesaBoogie from "../../assets/svg/Footer/MesaBoogie.svg";
import EpiphoneMobile from "../../assets/svg/Footer/Mobile/Epiphone.svg";
import GibsonMobile from "../../assets/svg/Footer/Mobile/Gibson.svg";
import KramerMobile from "../../assets/svg/Footer/Mobile/Kramer.svg";
import KRKMobile from "../../assets/svg/Footer/Mobile/KRK.svg";
import MaestroMobile from "../../assets/svg/Footer/Mobile/Maestro.svg";
import MesaBoogieMobile from "../../assets/svg/Footer/Mobile/MesaBoogie.svg";
import Steinberger from "../../assets/svg/Footer/Steinberger.svg";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";

export interface FooterProps {}

const Footer: FC<FooterProps> = (props) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <footer className='bg-gray-1 text-white px-10 pb-[3rem] md:px-28 '>
            <div className='pt-12 pb-8 container mx-auto'>
                <h6 className='text-center text-sm uppercase font-condensed font-bold'>
                    Visit The Gibson Brands Family
                </h6>
            </div>
            <div className='flex flex-wrap gap-[1rem] items-center justify-around md:justify-between md:px-16	pb-8'>
                <img
                    src={isMobile ? GibsonMobile : Gibson }
                    className='h-[20%] w-[28%] md:w-auto md:h-auto'
                />
                <img
                    src={isMobile ? EpiphoneMobile : Epiphone }
                    className='h-[20%] w-[28%] md:w-auto md:h-auto'
                />
                <img
                    src={isMobile ? KramerMobile : Kramer }
                    className='h-[20%] w-[28%] md:w-auto md:h-auto'
                />
                <img
                    src={isMobile ? MesaBoogieMobile : MesaBoogie }
                    className='h-[20%] w-[28%] md:w-auto md:h-auto'
                />
                <img
                    src={isMobile ? KRKMobile : KRK }
                    className='h-[20%] w-[28%] md:w-auto md:h-auto'
                />
                <img
                    src={Maestro}
                    className='h-[20%] w-[28%] md:w-auto md:h-auto'
                />
                {/* {isMobile && (
                    <img
                        src={Steinberger}
                        className='h-[20%] w-[28%] md:w-auto md:h-auto'
                    />
                )} */}
            </div>
            <div className='flex flex-col md:flex-row items-center justify-between pb-6'>
                <h6 className='text-[10px] font-normal'>
                    Copyright 2020 Gibson Brands, Inc. All rights reserved.
                </h6>
                <div className='flex min-w-fit'>
                    <Button
                        classes='px-0 text-[10px] font-normal capitalize whitespace-nowrap '
                        externalLink='https://www.gibson.com/Privacy-Policy'>
                        Privacy Policy |&nbsp;
                    </Button>
                    <Button classes='px-0 text-[10px] font-normal capitalize whitespace-nowrap '>
                        Terms & Conditions |&nbsp;
                    </Button>
                    <Button
                        classes='px-0 text-[10px] font-normal capitalize whitespace-nowrap '
                        externalLink='https://www.gibson.com/Trademarks-of-Gibson-Brands-Inc'>
                        Registered Trademarks &nbsp;
                    </Button>
                    {/* <Button classes='px-0 text-[10px] font-normal capitalize whitespace-nowrap '>
                        Site Map&nbsp;
                    </Button> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
