import { StyleGuideProps } from "../types";
import * as Assets from "../Assets/Epiphone";


export const EpiphoneStyleGuide = (isMobile?: boolean): StyleGuideProps => {
    return {
        logo: [
            {
                title: "Guidelines",
                p1: "For immediate brand recognition and impact, the Epiphone logo should not be crowded by other visual elements. 4x = Height of the Epiphone logo The minimum clear space for the Epiphone logo is x around the logo. Do not place graphics or typography in the clear space area. ",
                p2: "For maximum legibility, the background should be solid and provide good contrast with the logo. When the background is dark, a reverse logo, in white, may be used.",
                p3: "When presented alongside other brands, the Epiphone logo should enjoy parity of scale with the other logo(s). The grouping of logos should observe a minimum clear space of one-quarter the height of the Epiphone logo in all directions, with a shared clear distance between logos one-quarter the height of the Epiphone logo. • All logos should appear on same or equivalent • All logos should appear at the same scale • The logos should not include slogans, urls, etc",
                img1: isMobile
                    ? Assets.LogoGuidelinesMob
                    : Assets.LogoGuidelines,
                img2: isMobile
                    ? Assets.ColorAplicationMob
                    : Assets.ColorAplication,
            },
            {
                title: "RELATIONSHIPS ",
                p: "Epiphone’s logo may appear in Lockup with its tag line, “For Every Stage” (no punctuation). This Lockup is preferred in designs where the brand identity appears in open space, away from other text, and at a size that makes the tag line clearly legible.",
                img: Assets.LogoRelationships,
            },
            {
                title: "LOGO MISUSE ",
                p: "While creativity is something we champion, a strong brand identity depends on consistent presentation of our brand mark. Variations and improvisations of our logo — such as the misuse cases illustrated on this page — weaken the power and recognition of our brand in the marketplace. Please work with us before deviating from this visual guideline document or the logo files we make available.",
                images: [
                    {
                        img: isMobile ? Assets.logo1Mob : Assets.logo1,
                        desc: "Crop logo",
                    },
                    {
                        img: isMobile ? Assets.logo2Mob : Assets.logo2,
                        desc: "Change the transparency of the logo",
                    },
                    {
                        img: isMobile ? Assets.logo3Mob : Assets.logo3,
                        desc: "Use different colors",
                    },
                    {
                        img: isMobile ? Assets.logo4Mob : Assets.logo4,
                        desc: "Change the size or position of the logo type",
                    },
                    {
                        img: isMobile ? Assets.logo5Mob : Assets.logo5,
                        desc: "Distort the logo",
                    },
                    {
                        img: isMobile ? Assets.logo6Mob : Assets.logo6,
                        desc: "Use dropshadow or any other effect",
                    },
                    {
                        img: isMobile ? Assets.logo7Mob : Assets.logo7,
                        desc: "Outline the logo",
                    },
                    {
                        img: isMobile ? Assets.logo8Mob : Assets.logo8,
                        desc: "Use background that provide insufiicient contrast",
                    },
                    {
                        img: isMobile ? Assets.logo9Mob : Assets.logo9,
                        desc: "Re-create using any other typeface",
                    },
                    {
                        img: isMobile ? Assets.logo10Mob : Assets.logo10,
                        desc: "Skew the logo",
                    },
                ],
            },
        ],
        color: {
            title: "COLOR GUIDELINES",
            p: "These colors are the visual tone of Epiphone. The palette is rich in complementary and contrasting hues that provide flexibility for any application. Use of color variants is acceptable in instances where more than 4 colors are needed, such as graphic, UI elements, and information design.",
            images: [
                {
                    img: isMobile ? Assets.Pick1Mob : Assets.Pick1,
                    desc: "HEX #7baba2",
                },
                {
                    img: isMobile ? Assets.Pick2Mob : Assets.Pick2,
                    desc: "HEX #006c60",
                },
                {
                    img: isMobile ? Assets.Pick3Mob : Assets.Pick3,
                    desc: "HEX #9c1c19",
                },
                {
                    img: isMobile ? Assets.Pick4Mob : Assets.Pick4,
                    desc: "HEX #292828",
                },
                {
                    img: isMobile ? Assets.Pick5Mob : Assets.Pick5,
                    desc: "HEX #FFFFFf",
                },
                {
                    img: isMobile ? Assets.Pick6Mob : Assets.Pick6,
                    desc: "HEX #EaC400",
                },
                {
                    img: isMobile ? Assets.Pick7Mob : Assets.Pick7,
                    desc: "HEX #000000",
                },
            ],
        },
        fonts: {
            title: "Font GUIDELINES ",
            p: "For everyday use we recommend using three styles: 900 (Bold), 300 (Regular) and 100 (Light).",
            fontClass: "fontEpiphone",
            fonts: [
                {
                    title: "EXPLORE.",
                    type: "MUSEO SANS • 900 • 120px",
                    ex: "HEADINGS",
                    class: "fontEpiphoneBold",
                },
                {
                    title: "PLAY.",
                    type: "MUSEO SANS • 300 • 120px",
                    ex: "PARAGRAPHS",
                    class: "fontEpiphoneReg",
                },
                {
                    title: "SHOP.",
                    type: "MUSEO SANS • 100 • 120px",
                    ex: "OPENING PARAGRAPHS",
                    class: "fontEpiphoneLight",
                },
            ],
        },
        images: {
            title: "PHOTO APPROACH",
            p1: "Epiphone’s visual storytelling reflects its more accessible brand identity, with an emphasis on comfort and approachability. Photographs allow the viewer to step in: a living room or a bedroom in a hip but familiar apartment.",
            p2: "The lighting is clean and soft. Instruments should be shown in their entirety, or as a body only, or in closeup detail — never a partial neck or haphazard crop. The lens is in the portrait range, 35-70mm. When people are seen, they tend to be relaxed, enthusiastic, smiling, with friends, and might seem relatively new to their instrument.",
            images: [
                isMobile ? Assets.Image1Mob : Assets.Image1,
                isMobile ? Assets.Image4Mob : Assets.Image2,
                isMobile ? Assets.Image2Mob : Assets.Image3,
                isMobile ? Assets.Image5Mob : Assets.Image4,
                isMobile ? Assets.Image3Mob : Assets.Image5,
                isMobile ? Assets.Image6Mob : Assets.Image6,
            ],
        },
    };
};
